import clsx from "clsx"
import humps from "humps"
import _ from "lodash"
import PropTypes from "prop-types"
import React from "react"

import Checkbox from "@material-ui/core/Checkbox"
import TableCell from "@material-ui/core/TableCell"
import MuiTableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { makeStyles } from "@material-ui/core/styles"
import SortAscIcon from "@material-ui/icons/ArrowDropDown"
import SortDescIcon from "@material-ui/icons/ArrowDropUp"

const useStyles = makeStyles({
  pointer: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  container: {
    display: "flex",
    "align-items": "center",
  },
})

const sortAsc = fieldName => `${humps.decamelize(fieldName)}_asc`
const sortDesc = fieldName => `${humps.decamelize(fieldName)}_desc`

const TableHead = ({
  fields,
  sort,
  onSortChange,
  selectedObjects,
  selectionDisabled,
  onToggleSelection,
  actionsHidden,
  actions,
}) => {
  const classes = useStyles()

  const sortEnabled = Boolean(onSortChange)

  const handleLabelClick = field => {
    return () => {
      let newSort
      if (sort == sortAsc(field.name)) {
        newSort = sortDesc(field.name)
      } else {
        newSort = sortAsc(field.name)
      }
      onSortChange(newSort)
    }
  }

  const renderSortIcon = field => {
    if (sort == sortAsc(field.name)) {
      return <SortAscIcon />
    } else if (sort == sortDesc(field.name)) {
      return <SortDescIcon />
    } else {
      return null
    }
  }

  const cells = fields.map(field => {
    if (field.hidden) return null
    const sortable = field.sortable === undefined ? true : field.sortable
    return (
      <TableCell
        className={clsx(sortEnabled && sortable && classes.pointer)}
        key={field.name}
        onClick={sortEnabled && sortable ? handleLabelClick(field) : null}
      >
        <div className={classes.container}>
          {field.label || field.name}
          {sortEnabled && renderSortIcon(field)}
        </div>
      </TableCell>
    )
  })

  const actionCells = []
  if (!actionsHidden) {
    actionCells.push(
      <TableCell key={-1} padding="checkbox">
        &nbsp;
      </TableCell>
    )
  }
  _.times(actions.length, key => {
    actionCells.push(
      <TableCell key={key} padding="checkbox">
        &nbsp;
      </TableCell>
    )
  })

  return (
    <MuiTableHead>
      <TableRow>
        {onToggleSelection && (
          <TableCell padding="checkbox">
            <Checkbox
              checked={selectedObjects.length > 0}
              onChange={onToggleSelection}
              disabled={selectionDisabled}
            />
          </TableCell>
        )}
        {cells}
        {actionCells}
      </TableRow>
    </MuiTableHead>
  )
}

TableHead.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string,
      schema: PropTypes.object,
      label: PropTypes.string,
      hidden: PropTypes.bool,
      readonly: PropTypes.bool,
      sortable: PropTypes.bool,
      multiline: PropTypes.bool,
    })
  ).isRequired,
  sort: PropTypes.string,
  onSortChange: PropTypes.func,
  selectedObjects: PropTypes.arrayOf(PropTypes.object),
  selectionDisabled: PropTypes.bool,
  onToggleSelection: PropTypes.func,
  actionsHidden: PropTypes.bool,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      onAction: PropTypes.func.isRequired,
      icon: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.func,
        PropTypes.node,
      ]),
    })
  ),
}

TableHead.defaultProps = {
  selectedObjects: [],
  selectionDisabled: false,
  actionsHidden: true,
  actions: [],
}

export default TableHead
