import React from "react"
import { useSelector } from "react-redux"

import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import AppContainer from "./AppContainer"
import ProfileForm from "./ProfileForm"
import { Tile } from "./elements"
import { SEO } from "./utils"

const TITLE = "Account"

const useStyles = makeStyles({
  fixedHeight: {
    height: 480,
  },
})

const AccountScreen = () => {
  const user = useSelector(state => state.auth.user)
  const classes = useStyles()

  const renderContent = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={6}>
          <Tile title="Profile" className={classes.fixedHeight}>
            <ProfileForm data={user} />
          </Tile>
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      <SEO title={TITLE} />
      <AppContainer title={TITLE}>{renderContent()}</AppContainer>
    </>
  )
}

export default AccountScreen
