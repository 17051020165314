import clsx from "clsx"
import _ from "lodash"
import PropTypes from "prop-types"
import React from "react"

import {
  Button,
  Checkbox,
  IconButton,
  TableRow as MuiTableRow,
  TableCell,
  Tooltip,
  Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Edit as EditIcon, Visibility as ViewIcon } from "@material-ui/icons"

import { formatValue } from "./utils"

const useStyles = makeStyles(theme => ({
  deleting: {
    color: theme.palette.action.disabled,
    textDecoration: "line-through",
  },
}))

const TableRow = ({
  data,
  fields,
  selected,
  onSelect,
  onEdit,
  editable,
  deleting,
  actions,
}) => {
  const classes = useStyles()

  const handleCheckboxChange = () => {
    onSelect(data)
  }

  const handleEditClick = () => {
    onEdit(data)
  }

  const renderValue = field => {
    const value = _.get(data, field.name)
    return formatValue(field, value)
  }

  const renderFields = () => {
    return fields.map(
      field =>
        !field.hidden && (
          <TableCell key={`${data.id}-${field.name}`}>
            <Typography
              className={clsx(deleting && classes.deleting)}
              variant="body2"
            >
              {renderValue(field)}
            </Typography>
          </TableCell>
        )
    )
  }

  // eslint-disable-next-line react/prop-types
  const renderButton = ({ title, onAction, icon, disabled }) => {
    if (deleting) return null

    let iconEl
    if (React.isValidElement(icon)) {
      iconEl = icon
    } else if (_.isFunction(icon)) {
      iconEl = icon(data)
    }

    let isDisabled = disabled
    if (_.isFunction(disabled)) {
      isDisabled = disabled(data)
    }

    if (iconEl) {
      return (
        <Tooltip title={title} aria-label={title}>
          <IconButton
            color="primary"
            onClick={() => onAction(data)}
            disabled={isDisabled}
          >
            {iconEl}
          </IconButton>
        </Tooltip>
      )
    } else {
      return (
        <Tooltip title={title} aria-label={title}>
          <Button
            color="primary"
            onClick={() => onAction(data)}
            disabled={isDisabled}
          >
            {title}
          </Button>
        </Tooltip>
      )
    }
  }

  const renderActions = () => {
    return _.map(actions, action => {
      return (
        <TableCell key={action.title} padding="checkbox">
          {renderButton(action)}
        </TableCell>
      )
    })
  }

  return (
    <MuiTableRow selected={selected}>
      {onSelect ? (
        <TableCell padding="checkbox">
          {deleting ? null : (
            <Checkbox checked={selected} onChange={handleCheckboxChange} />
          )}
        </TableCell>
      ) : null}
      {renderFields()}
      {onEdit ? (
        <TableCell padding="checkbox">
          {deleting ? null : (
            <Tooltip
              title={editable ? "Edit" : "View"}
              aria-label={editable ? "Edit" : "View"}
            >
              <IconButton color="primary" onClick={handleEditClick}>
                {editable ? <EditIcon /> : <ViewIcon />}
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
      ) : null}
      {renderActions()}
    </MuiTableRow>
  )
}

TableRow.propTypes = {
  data: PropTypes.object.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string,
      schema: PropTypes.object,
      label: PropTypes.string,
      hidden: PropTypes.bool,
      readonly: PropTypes.bool,
      sortable: PropTypes.bool,
    })
  ).isRequired,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  onEdit: PropTypes.func,
  editable: PropTypes.bool,
  deleting: PropTypes.bool,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      onAction: PropTypes.func.isRequired,
      icon: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.func,
        PropTypes.node,
      ]),
    })
  ),
}

export default TableRow
