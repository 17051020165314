/**
 * @flow
 */

import { Field, Formik } from "formik"
import React from "react"
import * as yup from "yup"

import { Grid } from "@material-ui/core"

import { validatePhoneNumber } from "../../utils/phonenumber"
import {
  EmailField,
  Form,
  PasswordField,
  SubmitButton,
  TextField,
} from "../elements"

const validationSchema = yup.object().shape({
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Required"),
  mobileNumber: yup
    .string()
    .required("Required")
    .test("phone-number", "Invalid phone number", value =>
      validatePhoneNumber(value)
    ),
  password: yup.string().required("Required"),
})

type Values = {
  firstName: string,
  lastName: string,
  email: string,
  mobileNumber: string,
  password: string,
}

type Actions = {
  setSubmitting: Function,
}

type Props = {
  onSubmit: (values: Values, actions: Actions) => void | Promise<mixed>,
  initialValues?: Values,
}

const SignUpForm = ({
  onSubmit,
  initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    password: "",
  },
}: Props) => (
  <Formik
    initialValues={initialValues}
    validateOnBlur={false}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    {({ isSubmitting }) => (
      <Form noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Field
              autoComplete="given-name"
              autoFocus
              component={TextField}
              id="firstName"
              label="First Name"
              name="firstName"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              autoComplete="family-name"
              component={TextField}
              id="lastName"
              label="Last Name"
              name="lastName"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              autoComplete="email"
              component={EmailField}
              id="email"
              label="Email Address"
              name="email"
              placeholder="someone@example.com"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              autoComplete="tel"
              component={TextField}
              id="mobileNumber"
              label="Phone Number"
              name="mobileNumber"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              autoComplete="current-password"
              component={PasswordField}
              id="password"
              label="Password"
              name="password"
              required
            />
          </Grid>
        </Grid>
        <SubmitButton
          color="primary"
          variant="contained"
          fullWidth
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          Create Account
        </SubmitButton>
      </Form>
    )}
  </Formik>
)

export default SignUpForm
