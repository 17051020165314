import _ from "lodash"
import PropTypes from "prop-types"
import React from "react"

import InputAdornment from "@material-ui/core/InputAdornment"
import TextField from "@material-ui/core/TextField"
import SearchIcon from "@material-ui/icons/Search"

const SearchField = ({ value, onChange, debounceWait, onSearch, ...props }) => {
  const search = _.debounce(onSearch, debounceWait)

  const handleChange = event => {
    const { value } = event.target
    onChange(value)
    search(value)
  }

  return (
    <TextField
      variant="outlined"
      placeholder="Search"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      value={value}
      onChange={handleChange}
      {...props}
    />
  )
}

SearchField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  debounceWait: PropTypes.number,
  onSearch: PropTypes.func,
}

SearchField.defaultProps = {
  value: "",
  onChange: () => {},
  onSearch: () => {},
  debounceWait: 500,
}

export default SearchField
