import React from "react"

import { Grid } from "@material-ui/core"

const AdminDashboard = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6} lg={6}></Grid>
      <Grid item xs={12} md={6} lg={6}></Grid>
    </Grid>
  )
}

export default AdminDashboard
