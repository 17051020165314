import PropTypes from "prop-types"
import React from "react"

import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"

const ConfirmationDialog = ({ title, message, onConfirm, open, onClose }) => {
  return (
    <Dialog
      open={open}
      aria-labelledby="confirmation-dialog-title"
      onClose={onClose}
    >
      <DialogTitle id="confirmation-dialog-title" onClose={onClose}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onConfirm}>
          Yes
        </Button>
        {onClose ? (
          <Button color="secondary" onClick={onClose}>
            No
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  )
}

ConfirmationDialog.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

ConfirmationDialog.defaultProps = {
  open: false,
}

export default ConfirmationDialog
