/**
 * @flow
 */

import { Auth, Logger } from "aws-amplify"
import { navigate } from "gatsby"
import _ from "lodash"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"

import { Router } from "@reach/router"

import {
  AccountScreen,
  DashboardScreen,
  NotFoundPage,
  PrivateRoute,
  ProfileListScreen,
  UnauthorizedPage,
  UserListScreen,
} from "../components"
import { notify, setUser } from "../state"

const isAdmin = user => {
  const groups = _.get(
    user,
    "signInUserSession.accessToken.payload.cognito:groups",
    []
  )
  return _.includes(groups, process.env.ADMIN_GROUP_NAME)
}

const App = () => {
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()

  const logger = useMemo(() => new Logger(App.name), [])

  const fetchAuthenticatedUser = useCallback(async () => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      logger.debug(user)

      if (!isAdmin(user)) {
        await Auth.signOut()
        dispatch(setUser())
        dispatch(
          notify({
            message: "You are not authorised to access this site!",
            variant: "warning",
          })
        )
        navigate("/sign-in", { replace: true })
      }

      dispatch(setUser(user.attributes))
    } catch (err) {
      logger.error(err)
      dispatch(setUser())
    } finally {
      setLoading(false)
    }
  }, [dispatch, logger])

  useEffect(() => {
    fetchAuthenticatedUser()
  }, [fetchAuthenticatedUser])

  if (loading) return null

  return (
    <Router>
      <PrivateRoute path="app" component={DashboardScreen} />
      <PrivateRoute
        path="app/account"
        component={AccountScreen}
        unauthorized={UnauthorizedPage}
      />
      <PrivateRoute
        path="app/users"
        component={UserListScreen}
        unauthorized={UnauthorizedPage}
      />
      <PrivateRoute
        path="app/profiles"
        component={ProfileListScreen}
        unauthorized={UnauthorizedPage}
      />
      <NotFoundPage default />
    </Router>
  )
}

export default App
