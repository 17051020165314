import { Auth } from "aws-amplify"
import { navigate } from "gatsby"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import {
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core"
import deepOrange from "@material-ui/core/colors/deepOrange"
import { makeStyles } from "@material-ui/core/styles"
import AccountCircleIcon from "@material-ui/icons/AccountCircle"

import { setUser } from "../state"
import PasswordChangeDialog from "./PasswordChangeDialog"

const useStyles = makeStyles({
  avatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: deepOrange[500],
  },
  avatarMenuItem: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
  },
})

const AccountButton = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false)
  const user = useSelector(state => state.auth.user)
  const dispatch = useDispatch()
  const classes = useStyles()

  const open = Boolean(anchorEl)
  const avatarLetters = `${user.given_name[0].toUpperCase()}${user.family_name[0].toUpperCase()}`

  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleAccountClick = () => {
    navigate("/app/account")
  }

  const handleChangePasswordClick = () => {
    setPasswordDialogOpen(true)
    handleMenuClose()
  }

  const handlePasswordDialogClose = () => {
    setPasswordDialogOpen(false)
  }

  const handleSignOut = async () => {
    await Auth.signOut()
    dispatch(setUser())
  }

  return (
    <>
      <IconButton
        aria-owns={open ? "account-menu-appbar" : undefined}
        aria-haspopup="true"
        onClick={handleMenuOpen}
        color="inherit"
      >
        <AccountCircleIcon />
      </IconButton>
      <Menu
        id="account-menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleMenuClose}
      >
        <MenuItem
          className={classes.avatarMenuItem}
          button={false}
          disableGutters
          divider
        >
          <Avatar className={classes.avatar}>{avatarLetters}</Avatar>
          <Typography variant="subtitle1">
            {user.firstName} {user.lastName}
          </Typography>
        </MenuItem>
        <MenuItem onClick={handleAccountClick}>Account</MenuItem>
        <MenuItem onClick={handleChangePasswordClick}>Change password</MenuItem>
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
      </Menu>
      <PasswordChangeDialog
        open={passwordDialogOpen}
        onClose={handlePasswordDialogClose}
      />
    </>
  )
}

export default AccountButton
