import PropTypes from "prop-types"
import React from "react"

import { Fab as MuiFab, Tooltip } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import AddIcon from "@material-ui/icons/Add"
import DeleteIcon from "@material-ui/icons/Delete"

const useStyles = makeStyles(theme => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
}))

const Fab = ({ action, onClick, ...props }) => {
  const classes = useStyles()

  const handleClick = () => {
    onClick(action)
  }

  if (action == "add") {
    return (
      <Tooltip title="Add" aria-label="Add">
        <MuiFab
          className={classes.fab}
          color="primary"
          aria-label="Add"
          onClick={onClick && handleClick}
          {...props}
        >
          <AddIcon />
        </MuiFab>
      </Tooltip>
    )
  } else {
    return (
      <Tooltip title="Delete" aria-label="Delete">
        <MuiFab
          className={classes.fab}
          color="secondary"
          aria-label="Delete"
          onClick={onClick && handleClick}
          {...props}
        >
          <DeleteIcon />
        </MuiFab>
      </Tooltip>
    )
  }
}

Fab.propTypes = {
  action: PropTypes.oneOf(["add", "delete"]).isRequired,
  onClick: PropTypes.func,
}

export default Fab
