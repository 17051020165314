/**
 * @flow
 */

import { Auth, Logger } from "aws-amplify"
import { navigate } from "gatsby"
import React, { useMemo } from "react"
import { useDispatch } from "react-redux"

import { Box, Container, Grid, Typography } from "@material-ui/core"

import { notify } from "../../state"
import { Copyright, Link, Logo } from "../elements"
import { SEO } from "../utils"
import ForgotPasswordForm from "./ForgotPasswordForm"
import FormContainer from "./FormContainer"

const ForgotPasswordPage = () => {
  const logger = useMemo(() => new Logger(ForgotPasswordPage.name), [])
  const dispatch = useDispatch()

  const handleSubmit = async (values, actions) => {
    const { email } = values
    try {
      await Auth.forgotPassword(email)
      navigate("/reset-password", {
        state: { email },
      })
    } catch (err) {
      logger.error(err)
      dispatch(
        notify({
          message: err.message,
          variant: "error",
        })
      )
    } finally {
      actions.setSubmitting(false)
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <SEO title="Forgot password" />
      <FormContainer>
        <Box mb={2} clone>
          <Logo size={64} />
        </Box>
        <Typography component="h1" variant="h5">
          Forgot password
        </Typography>
        <Typography variant="subtitle2">
          Send password reset instructions
        </Typography>
        <Box mt={1} clone>
          <ForgotPasswordForm onSubmit={handleSubmit} />
        </Box>
        <Grid container justify="flex-end">
          <Grid item>
            <Link to="/sign-in">Remember password? Sign in</Link>
          </Grid>
        </Grid>
      </FormContainer>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  )
}

export default ForgotPasswordPage
