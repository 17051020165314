import clsx from "clsx"
import PropTypes from "prop-types"
import React, { useState } from "react"

import {
  AppBar,
  Box,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  SwipeableDrawer,
  Toolbar,
  Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import {
  ChevronLeft as ChevronLeftIcon,
  Menu as MenuIcon,
} from "@material-ui/icons"

import AccountButton from "./AccountButton"
import DrawerList from "./DrawerList"
import { Logo } from "./elements"

const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

const DRAWER_WIDTH = 240

const useStyles = makeStyles(theme => ({
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: DRAWER_WIDTH,
    height: "100vh",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  drawerList: {
    width: 250,
  },
}))

const MiniDrawer = ({ open, onClose }) => {
  const classes = useStyles()
  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      open={open}
    >
      <div className={classes.toolbarIcon}>
        <Box ml="8px">
          <Logo size={24} />
        </Box>
        <IconButton onClick={onClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <DrawerList />
    </Drawer>
  )
}

MiniDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

const SwipeableTemporaryDrawer = ({ open, onClose, onOpen }) => {
  const classes = useStyles()
  return (
    <SwipeableDrawer
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      open={open}
      onClose={onClose}
      onOpen={onOpen}
    >
      <div tabIndex={0} role="button" onClick={onClose} onKeyDown={onClose}>
        <div className={classes.drawerList}>
          <DrawerList />
        </div>
      </div>
    </SwipeableDrawer>
  )
}

SwipeableTemporaryDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
}

const AppNav = ({ title }) => {
  const [open, setOpen] = useState(true)
  const classes = useStyles()
  const shiftAppBar = useMediaQuery("(min-width:960px)")

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <>
      <AppBar
        position="absolute"
        className={clsx(
          classes.appBar,
          open && shiftAppBar && classes.appBarShift
        )}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="Open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && shiftAppBar && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {title}
          </Typography>
          <AccountButton />
        </Toolbar>
      </AppBar>
      <Hidden only={["xs", "sm"]}>
        <MiniDrawer open={open} onClose={handleDrawerClose} />
      </Hidden>
      <Hidden only={["md", "lg", "xl"]}>
        <SwipeableTemporaryDrawer
          open={open}
          onClose={handleDrawerClose}
          onOpen={handleDrawerOpen}
        />
      </Hidden>
    </>
  )
}

AppNav.propTypes = {
  title: PropTypes.string.isRequired,
}

export default AppNav
