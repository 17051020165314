import { Auth, Logger } from "aws-amplify"
import { Field, Formik } from "formik"
import PropTypes from "prop-types"
import React, { useMemo } from "react"
import { useDispatch } from "react-redux"
import * as yup from "yup"

import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
} from "@material-ui/core"

import { notify } from "../state"
import { DialogActions, DialogTitle, Form, PasswordField } from "./elements"

const validationSchema = yup.object().shape({
  oldPassword: yup.string().required("Required"),
  newPassword: yup.string().required("Required"),
  passwordConfirmation: yup
    .string()
    .required("Required")
    .oneOf([yup.ref("newPassword"), null], "Passwords don't match"),
})

const PasswordChangeDialog = ({ open, onClose }) => {
  const logger = useMemo(() => new Logger(PasswordChangeDialog.name), [])
  const dispatch = useDispatch()

  const initialValues = {
    oldPassword: "",
    newPassword: "",
    passwordConfirmation: "",
  }

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const { oldPassword, newPassword } = values
    let close = false
    try {
      const user = await Auth.currentAuthenticatedUser()
      await Auth.changePassword(user, oldPassword, newPassword)
      dispatch(
        notify({
          message: "Password changed successfully!",
          variant: "success",
        })
      )
      close = Boolean(onClose)
    } catch (err) {
      logger.error(err)

      if (err.code === "InvalidParameterException") {
        dispatch(
          notify({
            message: err.message,
            variant: "error",
          })
        )
      } else {
        dispatch(
          notify({
            message: "There was a problem changing your password!",
            variant: "error",
          })
        )
      }
    } finally {
      setSubmitting(false)
      resetForm(initialValues)
    }

    if (close) {
      onClose()
    }
  }

  const renderFields = () => (
    <>
      <Field
        component={PasswordField}
        id="oldPassword"
        name="oldPassword"
        label="Old Password"
        autoFocus
        required
      />
      <Field
        component={PasswordField}
        id="newPassword"
        name="newPassword"
        label="New Password"
        required
      />
      <Field
        component={PasswordField}
        id="passwordConfirmation"
        name="passwordConfirmation"
        label="Confirm Password"
        required
      />
    </>
  )

  // eslint-disable-next-line react/prop-types
  const renderForm = ({ dirty, submitForm, isSubmitting }) => (
    <Form noValidate>
      <DialogTitle id="form-dialog-title" onClose={onClose}>
        Change Password
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>Enter your new password below.</DialogContentText>
        {renderFields()}
      </DialogContent>
      <DialogActions>
        {isSubmitting ? <CircularProgress /> : null}
        <Button
          color="primary"
          onClick={submitForm}
          disabled={!dirty || isSubmitting}
        >
          Change Password
        </Button>
        {onClose ? (
          <Button color="secondary" onClick={onClose} disabled={isSubmitting}>
            Cancel
          </Button>
        ) : null}
      </DialogActions>
    </Form>
  )

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title" onClose={onClose}>
      <Formik
        initialValues={initialValues}
        validateOnBlur={false}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {renderForm}
      </Formik>
    </Dialog>
  )
}

PasswordChangeDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

export default PasswordChangeDialog
