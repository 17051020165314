import { Field, Formik } from "formik"
import _ from "lodash"
import PropTypes from "prop-types"
import React from "react"
import * as yup from "yup"

import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  TextField as MuiTextField,
} from "@material-ui/core"

import { DialogActions, DialogTitle, Form, TextField } from "../elements"
import { formatValue } from "./utils"

const FormDialog = ({
  title,
  description,
  fields,
  data,
  saveObject,
  open,
  onClose,
}) => {
  const adding = !data || !data.id
  const readonly = !saveObject

  const initialValues = fields.reduce((values, field) => {
    const object = {
      ...values,
    }
    const value = _.get(data, field.name, "")
    return _.set(object, field.name, value)
  }, {})

  const validationSchema = yup
    .object()
    .shape(
      fields.reduce(
        (schema, field) =>
          field.schema ? { ...schema, [field.name]: field.schema } : schema,
        {}
      )
    )

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await saveObject({
        ...values,
        id: data && data.id,
      })
    } catch (err) {
      console.warn(err)
    }
    setSubmitting(false)
  }

  const renderFields = () => {
    return fields.map((field, index) => {
      const required = Boolean(
        field.schema &&
          _.find(field.schema.tests, { OPTIONS: { name: "required" } })
      )
      if (field.readonly) {
        if (adding) return null

        const value = formatValue(field, _.get(data, field.name))
        return (
          <MuiTextField
            key={field.name}
            id={field.name}
            name={field.name}
            label={field.label}
            value={value}
            fullWidth
            variant="outlined"
            margin="normal"
            disabled
            multiline={field.multiline}
          />
        )
      } else {
        return (
          <Field
            key={field.name}
            component={TextField}
            id={field.name}
            name={field.name}
            label={field.label}
            autoFocus={index === 0}
            required={required}
            disabled={readonly}
            multiline={field.multiline}
          />
        )
      }
    })
  }

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title" onClose={onClose}>
      <Formik
        initialValues={initialValues}
        validateOnBlur={false}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ dirty, submitForm, isSubmitting }) => (
          <Form noValidate>
            <DialogTitle id="form-dialog-title" onClose={onClose}>
              {title}
            </DialogTitle>
            <DialogContent dividers>
              <DialogContentText>{description}</DialogContentText>
              {renderFields()}
            </DialogContent>
            <DialogActions>
              {isSubmitting ? <CircularProgress /> : null}
              {saveObject ? (
                <Button
                  color="primary"
                  onClick={submitForm}
                  disabled={!dirty || isSubmitting}
                >
                  {data && data.id ? "Save Changes" : "Create"}
                </Button>
              ) : null}
              {onClose ? (
                <Button
                  color="secondary"
                  onClick={onClose}
                  disabled={isSubmitting}
                >
                  {readonly ? "Close" : "Cancel"}
                </Button>
              ) : null}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

FormDialog.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string,
      schema: PropTypes.object,
      label: PropTypes.string,
      hidden: PropTypes.bool,
      readonly: PropTypes.bool,
      sortable: PropTypes.bool,
      multiline: PropTypes.bool,
    })
  ).isRequired,
  data: PropTypes.object,
  saveObject: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

FormDialog.defaultProps = {
  data: {},
  open: false,
}

export default FormDialog
