import React from "react"
import { connect } from "react-redux"

import AdminDashboard from "./AdminDashboard"
import AppContainer from "./AppContainer"
import { SEO } from "./utils"

const TITLE = "Dashboard"

const DashboardScreen = () => {
  return (
    <>
      <SEO title={TITLE} />
      <AppContainer title={TITLE}>
        <AdminDashboard />
      </AppContainer>
    </>
  )
}

const mapStateToProps = state => ({
  user: state.auth.user,
})

export default connect(mapStateToProps)(DashboardScreen)
