/**
 * @flow
 */

import React from "react"
import type { Node } from "react"

import { Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}))

type Props = {
  children?: Node,
}

const FormContainer = ({ children, ...props }: Props) => {
  const classes = useStyles()
  return (
    <Box className={classes.root} {...props}>
      {children}
    </Box>
  )
}

export default FormContainer
