import gql from "graphql-tag"
import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"

import Box from "@material-ui/core/Box"
import Paper from "@material-ui/core/Paper"

import AppContainer from "./AppContainer"
import GraphqlTable from "./graphql-table"
import { SEO } from "./utils"

const TITLE = "Users"

const UserListScreen = () => {
  return (
    <>
      <SEO title={TITLE} />
      <AppContainer title={TITLE}>
        <Paper>
          <Box p={2}>
            <GraphqlTable
              title={TITLE}
              typeName="User"
              fields={[
                {
                  name: "email",
                  label: "Email",
                },
                {
                  name: "createdAt",
                  label: "Created At",
                  type: "datetime",
                },
              ]}
              query={USERS_QUERY}
              connectionName="users"
              searchVariable="filter"
              sortVariable="sort"
            />
          </Box>
        </Paper>
      </AppContainer>
    </>
  )
}

UserListScreen.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

export default connect()(UserListScreen)

const USERS_QUERY = gql`
  query UsersQuery($cursor: String, $filter: String, $sort: [UserSortEnum]) {
    users(first: 20, after: $cursor, filter: $filter, sort: $sort) {
      edges {
        node {
          id
          email
          createdAt
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`
