import { Auth, Logger } from "aws-amplify"
import { Field, Formik } from "formik"
import PropTypes from "prop-types"
import React, { useMemo } from "react"
import { useDispatch } from "react-redux"
import * as yup from "yup"

import MuiTextField from "@material-ui/core/TextField"

import { notify, setUser } from "../state/actions"
import {
  toInternationalPhoneNumber,
  validatePhoneNumber,
} from "../utils/phonenumber"
import { Form, SubmitButton, TextField } from "./elements"

const validationSchema = yup.object().shape({
  given_name: yup.string().required(),
  family_name: yup.string().required(),
  phone_number: yup
    .string()
    .required()
    .test("phone-number", "Invalid phone number", value =>
      validatePhoneNumber(value)
    ),
})

const ProfileForm = ({ data }) => {
  const dispatch = useDispatch()
  const logger = useMemo(() => new Logger(ProfileForm.name), [])

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const { given_name, family_name, phone_number } = values
    const phoneNumber = toInternationalPhoneNumber(phone_number)
    try {
      const user = await Auth.currentAuthenticatedUser()
      await Auth.updateUserAttributes(user, {
        given_name,
        family_name,
        phone_number: phoneNumber,
      })

      user.getUserData(
        (err, data) => {
          if (err) {
            logger.error(err)
          } else {
            logger.debug("User data refreshed successfully.", data)
          }
        },
        { bypassCache: true }
      )

      dispatch(
        notify({
          message: "Profile saved successfully.",
          variant: "success",
        })
      )

      dispatch(
        setUser({
          ...values,
          email: data.email,
        })
      )
    } catch (err) {
      console.error(err)
    } finally {
      setSubmitting(false)
      resetForm({
        ...data,
        ...values,
      })
    }
  }

  // eslint-disable-next-line react/prop-types
  const renderForm = ({ dirty, isSubmitting }) => (
    <Form noValidate>
      <Field
        autoComplete="given-name"
        autoFocus
        component={TextField}
        id="given_name"
        label="First Name"
        name="given_name"
        required
      />
      <Field
        autoComplete="family-name"
        component={TextField}
        id="family_name"
        label="Last Name"
        name="family_name"
        required
      />
      <MuiTextField
        id="email"
        name="email"
        label="Email Address"
        value={data.email}
        fullWidth
        variant="outlined"
        margin="normal"
        disabled
      />
      <Field
        autoComplete="tel"
        component={TextField}
        id="phone_number"
        label="Phone Number"
        name="phone_number"
        required
      />
      <SubmitButton
        color="primary"
        variant="contained"
        fullWidth
        loading={isSubmitting}
        disabled={!dirty || isSubmitting}
      >
        Save Changes
      </SubmitButton>
    </Form>
  )

  return (
    <Formik
      validateOnBlur={false}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      initialValues={data}
    >
      {renderForm}
    </Formik>
  )
}

ProfileForm.propTypes = {
  data: PropTypes.object,
}

ProfileForm.defaultProps = {
  data: {},
}

export default ProfileForm
