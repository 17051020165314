/**
 * @flow
 */

import { Auth, Logger } from "aws-amplify"
import { navigate } from "gatsby"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"

import { Box, Container, Grid, Typography } from "@material-ui/core"

import { notify } from "../../state"
import { Copyright, Link, Logo } from "../elements"
import { SEO } from "../utils"
import FormContainer from "./FormContainer"
import SignInForm from "./SignInForm"

const SignInPage = () => {
  const [loading, setLoading] = useState(true)
  const logger = useMemo(() => new Logger(SignInPage.name), [])
  const dispatch = useDispatch()

  const checkAuthenticatedUser = useCallback(async () => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      // Got authenticated user
      navigate("/app", { replace: true })
      dispatch(
        notify({
          message: `Welcome back ${user.attributes.given_name}.`,
          variant: "info",
        })
      )
    } catch (err) {
      // No authenticated user
      setLoading(false)
    }
  }, [dispatch])

  useEffect(() => {
    checkAuthenticatedUser()
  }, [checkAuthenticatedUser])

  const handleSubmit = async (values, actions) => {
    const { email, password } = values
    try {
      const user = await Auth.signIn(email, password)
      logger.info(user)
      dispatch(
        notify({
          message: "You signed in successfully.",
          variant: "success",
        })
      )
      setTimeout(() => {
        navigate("/app", { replace: true })
      }, 500)
    } catch (err) {
      logger.error(err)
      if (err.code === "UserNotConfirmedException") {
        await Auth.resendSignUp(email)
        navigate("/confirm-sign-up", {
          state: { email, password },
        })
      } else {
        dispatch(
          notify({
            message: err.message,
            variant: "error",
          })
        )
      }
    } finally {
      actions.setSubmitting(false)
    }
  }

  if (loading) return null

  return (
    <Container component="main" maxWidth="xs">
      <SEO title="Sign in" />
      <FormContainer>
        <Box mb={2} clone>
          <Logo size={64} />
        </Box>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box mt={1} clone>
          <SignInForm onSubmit={handleSubmit} />
        </Box>
        <Grid container>
          <Grid item xs>
            <Link to="/forgot-password">Forgot password?</Link>
          </Grid>
          <Grid item>
            <Link to="/sign-up">{"Don't have an account? Sign up"}</Link>
          </Grid>
        </Grid>
      </FormContainer>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  )
}

export default SignInPage
