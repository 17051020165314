import PropTypes from "prop-types"
import React from "react"

import Container from "@material-ui/core/Container"
import { makeStyles } from "@material-ui/core/styles"

import AppNav from "./AppNav"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    // height: "100vh", // breaks the "react-on-screen" component
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}))

const AppContainer = ({ children, title }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <AppNav title={title} />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container className={classes.container}>{children}</Container>
      </main>
    </div>
  )
}

AppContainer.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
}

export default AppContainer
