import PropTypes from "prop-types"
import React, { useEffect } from "react"
import TrackVisibility from "react-on-screen"

const ComponentToTrack = ({ isVisible, onVisible }) => {
  useEffect(() => {
    if (isVisible) {
      onVisible()
    }
  })

  return <div />
}

ComponentToTrack.propTypes = {
  isVisible: PropTypes.bool,
  onVisible: PropTypes.func.isRequired,
}

const VisibilityTrigger = ({ onVisible }) => {
  return (
    <TrackVisibility partialVisibility>
      <ComponentToTrack onVisible={onVisible} />
    </TrackVisibility>
  )
}

VisibilityTrigger.propTypes = {
  onVisible: PropTypes.func.isRequired,
}

export default VisibilityTrigger
