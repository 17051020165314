import { navigate } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"

import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import {
  Dashboard as DashboardIcon,
  Face as ProfilesIcon,
  SupervisorAccount as UsersIcon,
} from "@material-ui/icons"

const DrawerList = () => {
  return (
    <List>
      <ListItem button onClick={() => navigate("/app")}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>
      <ListItem button onClick={() => navigate("/app/users")}>
        <ListItemIcon>
          <UsersIcon />
        </ListItemIcon>
        <ListItemText primary="Users" />
      </ListItem>
      <ListItem button onClick={() => navigate("/app/profiles")}>
        <ListItemIcon>
          <ProfilesIcon />
        </ListItemIcon>
        <ListItemText primary="Profiles" />
      </ListItem>
    </List>
  )
}

DrawerList.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  user: state.auth.user,
})

export default connect(mapStateToProps)(DrawerList)
