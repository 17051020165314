import _ from "lodash"
import pluralize from "pluralize"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Mutation } from "react-apollo"

import { useQuery } from "@apollo/react-hooks"
import { CircularProgress, Toolbar, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import ConfirmationDialog from "./ConfirmationDialog"
import Fab from "./Fab"
import FormDialog from "./FormDialog"
import ResultTable from "./ResultTable"
import SearchField from "./SearchField"

const noop = (...args) => {
  console.warn(...args)
}

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
  },
  searchField: {
    marginLeft: theme.spacing(2),
  },
  tableCellHead: {
    color: theme.palette.text.secondary,
    fontSize: "0.75rem",
    lineHeight: "1.3125rem",
    fontWeight: 500,
  },
}))

const GraphqlTable = ({
  title,
  typeName,
  fields,
  query,
  queryVariables: {
    searchString: initialSearchString,
    sort: initialSort,
    ...queryVariables
  },
  connectionName,
  saveMutation,
  editDisabled,
  deleteMutation,
  mutationVariables,
  searchVariable,
  sortVariable,
  multiselect,
  errorExtractor,
  onSuccess,
  onError,
  onResult,
  ...props
}) => {
  const [searchString, setSearchString] = useState()
  const [activeSort, setActiveSort] = useState()
  const [selectedObjects, setSelectedObjects] = useState([])
  const [activeObject, setActiveObject] = useState(null)
  const [objectsDeleting, setObjectsDeleting] = useState([])
  const [currentAction, setCurrentAction] = useState(null)

  useEffect(() => {
    setSearchString(initialSearchString)
    setActiveSort(initialSort)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const classes = useStyles()

  const result = useQuery(query, {
    variables: {
      [searchVariable]: initialSearchString,
      [sortVariable]: initialSort,
      ...queryVariables,
    },
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    if (!_.isEmpty(result.data) && _.isFunction(onResult)) {
      onResult(result.data)
    }
  }, [onResult, result])

  const loading = result.networkStatus === 1
  const fetchingMore = result.networkStatus === 3
  const polling = result.networkStatus === 6
  const fetching = result.networkStatus < 7
  const editable = Boolean(saveMutation)
  const selectable = Boolean(deleteMutation)
  const adding = currentAction == "add"
  const editing = currentAction == "edit"
  const deleting = currentAction == "delete"

  const extractError = result => {
    return errorExtractor ? errorExtractor(result) : null
  }

  const handleSearch = searchString => {
    setSelectedObjects([])

    result.refetch({
      [searchVariable]: searchString,
      [sortVariable]: activeSort,
      ...queryVariables,
    })
  }

  const handleSortChange = newSort => {
    setActiveSort(newSort)
    setSelectedObjects([])

    result.refetch({
      [searchVariable]: searchString,
      [sortVariable]: newSort,
      ...queryVariables,
    })
  }

  const handleSelect = object => {
    let objects = [...selectedObjects]
    if (multiselect) {
      const obj = _.find(objects, { id: object.id })
      if (obj) {
        _.remove(objects, { id: object.id })
      } else {
        objects.push(object)
      }
    } else {
      if (objects[0] == object) {
        objects = []
      } else {
        objects = [object]
      }
    }
    setSelectedObjects(objects)
  }

  const handleToggleSelection = () => {
    if (selectedObjects.length > 0) {
      setSelectedObjects([])
    } else {
      const objects = _(result.data)
        .get(`${connectionName}.edges`, [])
        .map(edge => edge.node)
      setSelectedObjects(objects)
    }
  }

  const handleEdit = object => {
    setActiveObject(object)
    setCurrentAction("edit")
  }

  const handleDialogClose = () => {
    setCurrentAction(null)
  }

  const handleSave = saveObject => {
    return async values => {
      let saveResult
      try {
        saveResult = await saveObject({
          variables: {
            ...values,
            ...(_.isFunction(mutationVariables)
              ? mutationVariables(result.data)
              : mutationVariables),
          },
        })

        const err = extractError(saveResult)
        if (err) {
          onError(err.message)
        } else {
          if (!values.id) {
            result.refetch({
              [searchVariable]: searchString,
              [sortVariable]: activeSort,
              ...queryVariables,
            })
          }

          handleDialogClose()

          onSuccess(`${typeName} saved successfully.`)
        }
      } catch (err) {
        onError(`There was a problem saving ${typeName.toLowerCase()}!`)
        throw err
      }
      return saveResult
    }
  }

  const handleDelete = deleteObjects => {
    return async () => {
      const ids = selectedObjects.map(object => object.id)

      setObjectsDeleting(selectedObjects)
      setSelectedObjects([])
      handleDialogClose()

      let deleteResult
      try {
        deleteResult = await deleteObjects({
          variables: {
            ids,
            ...(_.isFunction(mutationVariables)
              ? mutationVariables(result.data)
              : mutationVariables),
          },
        })

        await result.refetch({
          [searchVariable]: searchString,
          [sortVariable]: activeSort,
          ...queryVariables,
        })

        onSuccess(
          `${ids.length} ${pluralize(
            typeName.toLowerCase(),
            ids.length
          )} deleted.`
        )
      } catch (err) {
        onError(
          `There was a problem deleting ${pluralize(
            typeName.toLowerCase(),
            ids.length
          )}!`
        )
        throw err
      } finally {
        setObjectsDeleting([])
      }

      return deleteResult
    }
  }

  const handleFabClick = action => {
    setActiveObject({})
    setCurrentAction(action)
  }

  const renderFab = () => {
    if (deleteMutation && selectedObjects.length > 0) {
      return <Fab action="delete" onClick={handleFabClick} />
    }

    if (saveMutation) {
      return <Fab action="add" onClick={handleFabClick} />
    }

    return null
  }

  const renderFormDialog = () => {
    let title
    let description
    if (activeObject && activeObject.id) {
      title = editable ? `Edit ${typeName}` : typeName
      description = editable
        ? `Change an existing ${typeName.toLowerCase()}.`
        : `View an existing ${typeName.toLowerCase()}.`
    } else {
      title = `Add ${typeName}`
      description = `Create a new ${typeName.toLowerCase()}.`
    }

    if (saveMutation) {
      return (
        <Mutation mutation={saveMutation}>
          {saveObject => (
            <FormDialog
              title={title}
              description={description}
              fields={fields}
              data={activeObject}
              saveObject={handleSave(saveObject)}
              open={adding || editing}
              onClose={handleDialogClose}
            />
          )}
        </Mutation>
      )
    } else {
      return (
        <FormDialog
          title={title}
          description={description}
          fields={fields}
          data={activeObject}
          open={adding || editing}
          onClose={handleDialogClose}
        />
      )
    }
  }

  const renderDeleteConfirmationDialog = () => {
    if (deleteMutation) {
      const title = `Delete ${pluralize(typeName, selectedObjects.length)}`
      const message = `Are you sure you want to delete the selected ${pluralize(
        typeName.toLowerCase(),
        selectedObjects.length
      )}?`

      return (
        <Mutation mutation={deleteMutation}>
          {deleteObjects => (
            <ConfirmationDialog
              title={title}
              message={message}
              onConfirm={handleDelete(deleteObjects)}
              open={deleting}
              onClose={handleDialogClose}
            />
          )}
        </Mutation>
      )
    }
    return null
  }

  return (
    <>
      <Toolbar disableGutters>
        <Typography className={classes.title} variant="h6">
          {title}
        </Typography>
        {fetching && <CircularProgress />}
        {searchVariable && (
          <SearchField
            className={classes.searchField}
            value={searchString}
            onChange={setSearchString}
            onSearch={handleSearch}
            disabled={loading || fetchingMore || polling}
          />
        )}
      </Toolbar>
      {selectable ? (
        <Typography className={classes.tableCellHead}>
          {selectedObjects.length} selected
        </Typography>
      ) : null}
      <ResultTable
        result={result}
        connectionName={connectionName}
        fields={fields}
        sort={activeSort}
        onSortChange={handleSortChange}
        selectedObjects={selectedObjects}
        objectsDeleting={objectsDeleting}
        onSelect={selectable ? handleSelect : null}
        onToggleSelection={selectable ? handleToggleSelection : null}
        onEdit={editDisabled ? null : handleEdit}
        editable={editable}
        {...props}
      />
      {renderFormDialog()}
      {renderDeleteConfirmationDialog()}
      {renderFab()}
    </>
  )
}

GraphqlTable.propTypes = {
  title: PropTypes.string,
  typeName: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string,
      schema: PropTypes.object,
      label: PropTypes.string,
      hidden: PropTypes.bool,
      readonly: PropTypes.bool,
      sortable: PropTypes.bool,
      multiline: PropTypes.bool,
    })
  ).isRequired,
  query: PropTypes.object.isRequired,
  queryVariables: PropTypes.object,
  connectionName: PropTypes.string.isRequired,
  saveMutation: PropTypes.object,
  editDisabled: PropTypes.bool,
  deleteMutation: PropTypes.object,
  mutationVariables: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  searchVariable: PropTypes.string,
  sortVariable: PropTypes.string,
  multiselect: PropTypes.bool,
  errorExtractor: PropTypes.func,
  onSuccess: PropTypes.func,
  onWarning: PropTypes.func,
  onError: PropTypes.func,
  onInfo: PropTypes.func,
  onResult: PropTypes.func,
}

GraphqlTable.defaultProps = {
  queryVariables: {},
  mutationVariables: {},
  multiselect: true,
  onSuccess: noop,
  onWarning: noop,
  onError: noop,
  onInfo: noop,
}

export default GraphqlTable
