/**
 * @flow
 */

import { Auth, Logger } from "aws-amplify"
import { navigate } from "gatsby"
import React, { useMemo } from "react"
import { useDispatch } from "react-redux"

import { Box, Container, Grid, Typography } from "@material-ui/core"

import { notify } from "../../state"
import { toInternationalPhoneNumber } from "../../utils/phonenumber"
import { Copyright, Link, Logo } from "../elements"
import { SEO } from "../utils"
import FormContainer from "./FormContainer"
import SignUpForm from "./SignUpForm"

const SignUpPage = () => {
  const logger = useMemo(() => new Logger(SignUpPage.name), [])
  const dispatch = useDispatch()

  const handleSubmit = async (values, actions) => {
    const { firstName, lastName, email, mobileNumber, password } = values
    const phoneNumber = toInternationalPhoneNumber(mobileNumber)
    try {
      const result = await Auth.signUp({
        username: email,
        password,
        attributes: {
          given_name: firstName,
          family_name: lastName,
          email,
          phone_number: phoneNumber,
        },
      })
      logger.info("Sign up success.", result)

      if (result.userConfirmed) {
        const user = await Auth.signIn(email, password)
        logger.info("Sign in success.", user)
        navigate("/", { replace: true })
      } else {
        navigate("/confirm-sign-up", {
          state: { email, password },
        })
      }
    } catch (err) {
      logger.error(err)
      dispatch(
        notify({
          message: err.message,
          variant: "error",
        })
      )
    } finally {
      actions.setSubmitting(false)
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <SEO title="Sign up" />
      <FormContainer>
        <Box mb={2} clone>
          <Logo size={64} />
        </Box>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Typography variant="subtitle2">Create a new account</Typography>
        <Box mt={1} clone>
          <SignUpForm onSubmit={handleSubmit} />
        </Box>
        <Grid container justify="flex-end">
          <Grid item>
            <Link to="/sign-in">Already have an account? Sign in</Link>
          </Grid>
        </Grid>
      </FormContainer>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  )
}

export default SignUpPage
