/**
 * @flow
 */

import { Auth, Logger } from "aws-amplify"
import { navigate } from "gatsby"
import React, { useMemo } from "react"
import { useDispatch } from "react-redux"

import { Box, Container, Grid, Typography } from "@material-ui/core"

import { notify } from "../../state"
import { Copyright, Link, Logo } from "../elements"
import { SEO } from "../utils"
import FormContainer from "./FormContainer"
import ResetPasswordForm from "./ResetPasswordForm"

type Props = {
  location: Object,
}

const ResetPasswordPage = ({ location }: Props) => {
  const logger = useMemo(() => new Logger(ResetPasswordPage.name), [])
  const dispatch = useDispatch()

  const handleSubmit = async (values, actions) => {
    const { code, password } = values
    const { email } = location.state
    try {
      await Auth.forgotPasswordSubmit(email, code, password)
      dispatch(
        notify({
          message: "Your password was reset successfully! You may now sign in.",
          variant: "success",
        })
      )
      navigate("/sign-in", { replace: true })
    } catch (err) {
      logger.error(err)
      dispatch(
        notify({
          message: err.message,
          variant: "error",
        })
      )
    } finally {
      actions.setSubmitting(false)
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <SEO title="Reset password" />
      <FormContainer>
        <Box mb={2} clone>
          <Logo size={64} />
        </Box>
        <Typography component="h1" variant="h5">
          Reset password
        </Typography>
        <Box mt={1} clone>
          <ResetPasswordForm onSubmit={handleSubmit} />
        </Box>
        <Grid container justify="flex-end">
          <Grid item>
            <Link to="/forgot-password">
              {"Didn't get the confirmation code? Try again"}
            </Link>
          </Grid>
        </Grid>
      </FormContainer>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  )
}

export default ResetPasswordPage
