import gql from "graphql-tag"
import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import { useDispatch } from "react-redux"

import { useMutation } from "@apollo/react-hooks"
import Box from "@material-ui/core/Box"
import Paper from "@material-ui/core/Paper"
import {
  ThumbUp as ApproveIcon,
  ThumbDown as RejectIcon,
} from "@material-ui/icons"

import { notify } from "../state/actions"
import AppContainer from "./AppContainer"
import GraphqlTable from "./graphql-table"
import { SEO } from "./utils"

const TITLE = "Profiles"

const ProfileListScreen = () => {
  const dispatch = useDispatch()

  const [approveProfile] = useMutation(APPROVE_PROFILE_MUTATION)
  const [rejectProfile] = useMutation(REJECT_PROFILE_MUTATION)

  const handleApprove = async data => {
    try {
      await approveProfile({
        variables: {
          profileId: data.id,
        },
      })
      dispatch(
        notify({
          message: `${data.givenName} ${data.familyName} approved.`,
          variant: "success",
        })
      )
    } catch (err) {
      console.error(err)
      dispatch(
        notify({
          message: `Failed to approve ${data.givenName} ${data.familyName}.`,
          variant: "error",
        })
      )
    }
  }

  const handleReject = async data => {
    try {
      await rejectProfile({
        variables: {
          profileId: data.id,
        },
      })
      dispatch(
        notify({
          message: `${data.givenName} ${data.familyName} rejected.`,
          variant: "success",
        })
      )
    } catch (err) {
      console.error(err)
      dispatch(
        notify({
          message: `Failed to reject ${data.givenName} ${data.familyName}.`,
          variant: "error",
        })
      )
    }
  }

  return (
    <>
      <SEO title={TITLE} />
      <AppContainer title={TITLE}>
        <Paper>
          <Box p={2}>
            <GraphqlTable
              title={TITLE}
              typeName="Profile"
              fields={[
                {
                  name: "owner.email",
                  label: "Email",
                },
                {
                  name: "givenName",
                  label: "First Name",
                },
                {
                  name: "familyName",
                  label: "Last Name",
                },
                {
                  name: "phoneNumber",
                  label: "Phone Number",
                },
                {
                  name: "biography",
                  label: "Biography",
                  hidden: true,
                  multiline: true,
                },
                {
                  name: "motivation",
                  label: "Motivation",
                  hidden: true,
                  multiline: true,
                },
                {
                  name: "deliveryAddress",
                  label: "Delivery Address",
                  hidden: true,
                  multiline: true,
                },
                {
                  name: "status",
                  label: "Status",
                },
                {
                  name: "voteCount",
                  label: "Votes",
                  readonly: true,
                },
                {
                  name: "createdAt",
                  label: "Created At",
                  type: "datetime",
                  hidden: true,
                },
              ]}
              query={PROFILES_QUERY}
              connectionName="profiles"
              searchVariable="filter"
              sortVariable="sort"
              actions={[
                {
                  title: "Approve",
                  onAction: handleApprove,
                  icon: <ApproveIcon />,
                  disabled: data => data.status === "APPROVED",
                },
                {
                  title: "Reject",
                  onAction: handleReject,
                  icon: <RejectIcon />,
                  disabled: data => data.status === "REJECTED",
                },
              ]}
            />
          </Box>
        </Paper>
      </AppContainer>
    </>
  )
}

ProfileListScreen.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

export default connect()(ProfileListScreen)

const PROFILES_QUERY = gql`
  query ProfilesQuery(
    $cursor: String
    $filter: String
    $sort: [ProfileSortEnum]
  ) {
    profiles(first: 20, after: $cursor, filter: $filter, sort: $sort) {
      edges {
        node {
          owner {
            id
            email
          }
          id
          givenName
          familyName
          phoneNumber
          biography
          motivation
          deliveryAddress
          status
          createdAt
          voteCount
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`

const APPROVE_PROFILE_MUTATION = gql`
  mutation ApproveProfile($profileId: ID!) {
    approveProfile(input: { profileId: $profileId }) {
      __typename
      ... on ApproveProfileSuccess {
        profile {
          id
          status
        }
      }
      ... on Error {
        message
      }
    }
  }
`

const REJECT_PROFILE_MUTATION = gql`
  mutation RejectProfile($profileId: ID!) {
    rejectProfile(input: { profileId: $profileId }) {
      __typename
      ... on RejectProfileSuccess {
        profile {
          id
          status
        }
      }
      ... on Error {
        message
      }
    }
  }
`
